import React from 'react';
import '../css/Skills.css'

function TechnologyDatabase({ database }) {
    return (
        <div>
            <h2>Technology Database</h2>
            <hr />
            <ul>
                {database.map(data => <li key={data}>{data}</li>)}
            </ul>
        </div>
    );
}

export default TechnologyDatabase;