import React from 'react';
import '../css/Skills.css'

function TechnologyWeb({ technologyWeb  }) {
    return (
        <div>
            <h2>Technology Web</h2>
            <hr />
            <ul>
                {technologyWeb.map(tech => <li key={tech}>{tech}</li>)}
            </ul>
        </div>
    );
}

export default TechnologyWeb;