import React from "react";
import '../css/ProfilePhoto.css'
import profileImage from '../img/profilewebsite.png'

// ! PROFILEPHOTO

function ProfilePhoto() {
    return <img src={profileImage} alt="Profile" style={{ width: '250px', height: '320px', borderRadius: '40%' }} />;
}


export default ProfilePhoto;