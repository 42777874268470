import React from 'react';
// import '../css/Experience.css'

// ! PROJECTS

function Projects({ projects }) {
    return (
        <div>
            <h2>Project</h2>
            <hr />
            {projects.map(proj => (
                <div key={proj.id}>
                    <p>Auth MERN<a href="https://auth-mern-ruddy.vercel.app/" target="_blank" rel="noopener noreferrer"> [Link]</a></p> 
                    <p>Face Detection<a href="https://github.com/rifqanzalbina/Face-Detection" target="_blank" rel="noopener noreferrer"> [Link]</a></p>
                    <p>Management Book API<a href="https://github.com/rifqanzalbina/ManagementBook-API" target="_blank" rel="noopener noreferrer"> [Link]</a></p>
                    <p>Brain ML Code / Machine Learning RepositoryI<a href="https://github.com/rifqanzalbina/BrainMLCode" target="_blank" rel="noopener noreferrer"> [Link]</a></p>
                </div>
            ))}
        </div>

    );
}

// ! Export Default 
export default Projects;

