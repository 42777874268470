import React, { useRef, useState } from 'react';
import Header from './components/javascript/Header';
import Footer from './components/javascript/Footer';
import ProfilePhoto from './components/javascript/ProfilePhoto';
import Skills from './components/javascript/Skills';
import Experience from './components/javascript/Experience';
import ProgrammingLanguages from './components/javascript/ProgrammingLanguages';
import Projects from './components/javascript/Projects';
import TechnologyWeb from './components/javascript/TechnologyWeb';
import TechnologyDatabase from './components/javascript/TechnologyDatabase';
import './App.css'; 

function App() {
    const profilePhoto = "./components/img/profilwebsite.png";
    const skills = ["Web", "Software Developer", "Cyber Security", "Mobile App (Still Progress)"];
    const experiences = [
        { id: 1, position: "Assistant Teacher", company: "BrigthChamp", duration: "3 Month" },
        { id: 2, position: "On Going", company: "Coming Soon", duration: "" }
    //     // Tambahkan lebih banyak pengalaman di sini
    ];
    const technologyWeb = ["React", "Svelte", "Angular", "Laravel"];
    const technologyDatabase = ["MongoDB", "MySQL", "PostgreSQL", "SQLite"];
    const languages = ["Python", "JavaScript", "Julia", "TypeScript", "Java", "Go", "Php", "R"];
    const projects = [ 
        { id: 1, name: "Project 1", link: "http://linkproject1.com" }
        // Tambahkan lebih banyak project di sini
    ];

    const [tooltip, setTooltip] = useState({ show: false, text: "" });
    const mainRef = useRef(null);

    const handleMouseMove = (text) => (event) => {
        const { clientX, clientY } = event;
        const { left, top, width, height } = mainRef.current.getBoundingClientRect();
        const tolerance = 20;
        const insideHorizontal = clientX > left - tolerance && clientX < left + width + tolerance;
        const insideVertical = clientY > top - tolerance && clientY < top + height + tolerance;

        if (insideHorizontal && insideVertical) {
            setTooltip({ show: true, text });
        } else {
            setTooltip({ show: false, text: "" });
        }
    };

    return (
        <div className="app">
            <Header />
            <main 
              className="main-content" 
              ref={mainRef} 
              onMouseMove={handleMouseMove("Hello ! 😁")}
            >
                <div className="profile-container">
                    <ProfilePhoto src={profilePhoto} />
                    <div className="profile-details">
                        <div onMouseMove={handleMouseMove("Skill saya")}>
                            <Skills skills={skills} />
                        </div>
                        <br/>
                        <div onMouseMove={handleMouseMove("Bahasa Pemrograman")}>
                            <ProgrammingLanguages languages={languages} />
                        </div>
                        <br/>
                        <div onMouseMove={handleMouseMove("Teknologi Web")}>
                            <TechnologyWeb technologyWeb={technologyWeb}/>
                        </div>
                        <br/>
                        <div onMouseMove={handleMouseMove("DBMS")}>
                            <TechnologyDatabase database={technologyDatabase}/>
                        </div>
                        <br/>
                        <div onMouseMove={handleMouseMove("Project (Real) and (Latihan)")}>
                            <Projects projects={projects} />
                        </div>
                        <br/>
                        <div onMouseMove={handleMouseMove("Pengalaman Bekerja")}>
                            <Experience experiences={experiences} />
                        </div>
                        <br/>
                    </div>
                </div>
                {tooltip.show && <div className="tooltip">{tooltip.text}</div>}
            </main>
            <Footer />
        </div>
    );
}


export default App;
