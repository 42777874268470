import React from 'react';
import '../css/Skills.css'

function Skills({ skills }) {
    return (
        <div>
            <h2>Skills</h2>
            <hr />
            <ul>
                {skills.map(skill => <li key={skill}>{skill}</li>)}
            </ul>
        </div>
    );
}

export default Skills;