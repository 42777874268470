import React from 'react';
import '../css/Skills.css'

// ! PROGRAMMINGLANGUAGE

function ProgrammingLanguages({ languages }) {
    return (
        <div>
            <h2>Programming Languages <span role="img" aria-label="emoji"></span></h2>
            <hr />
            <ul>
                {languages.map(lang => <li key={lang}>{lang}</li>)}
            </ul>
        </div>
    );
}

export default ProgrammingLanguages;