import React from 'react';
import '../css/Experience.css'

// ! EXPERIENCE

function Experience({ experiences }) {
    return (
        <div>
            <h2>Work experience</h2>
            <hr/>
            {experiences.map(exp => (
                <div key={exp.id}>
                    <h4>{exp.position}</h4>
                    <p>{exp.company} - {exp.duration}</p>
                </div>
            ))}
        </div>
    );
}

export default Experience;