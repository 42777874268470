import React from "react";
import '../css/Footer.css';

// ! FOOTER

function Footer(){
    return (
        <footer className="footer">
            <p>Website created by <a href="https://github.com/rifqanzalbina"> Rifqan </a></p>
        </footer>
    )
}

export default Footer;