import React, { useState } from 'react';
import '../css/Header.css';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Fungsi untuk menutup menu
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <header className="header">
            <div className="logo">Profile Rifqan</div>
            <div className="hamburger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <nav className={`mobile-nav ${isMenuOpen ? 'open' : ''}`}>
                <a href="https://github.com/rifqanzalbina" onClick={closeMenu}>GitHub</a> 
                <a href='https://www.linkedin.com/in/m-rifqan-zalbina-96a42a282/' onClick={closeMenu}>Linkedin</a>
                <a href='https://www.youtube.com/channel/UCpNSdL-lCrQwnLOHS93jiwQ' onClick={closeMenu}>Youtube</a>
                <a href='https://id.quora.com/profile/M-Rifqan-Zalbina/' onClick={closeMenu}>Quora</a>
            </nav>
        </header>
    );
}

export default Header;
